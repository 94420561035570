import { formatKg } from '@common/utils/formaters';
import { observer } from 'mobx-react';
import React, { FC, useMemo } from 'react';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

import { makeStyles } from '~/@components/@theme';
import { DashboardTitle, SimpleDashboardToolTip } from '~/@views/UserView/ProjectPage/ProjectDashboard/common';
import { dashboardFilterStore } from '~/@views/UserView/ProjectPage/ProjectDashboard/dashboardFilter.store';
import i18n from '~/i18n';

import { COLOR } from '../dashboard.theme';

export const ProjectCO2TrucksDashboard: FC = observer(() => {
    const { chart, header } = useStyles();

    const series = useMemo(() => {
        const map = dashboardFilterStore.filteredDeliveryItems.reduce((acc, cur) => {
            const licensePlate = cur.licensePlate;
            if (!acc.has(licensePlate)) acc.set(licensePlate, 0);

            acc.set(licensePlate, acc.get(licensePlate)! + cur.CO2Consumption);

            return acc;
        }, new Map<string, number>());

        const keys = Array.from(map.keys()).sort();

        return keys.map(k => ({ licensePlate: k, CO2: map.get(k)! }));
    }, [dashboardFilterStore.filteredDeliveryItems]);

    return (
        <div className={chart}>
            <div className={header}>
                <DashboardTitle>{i18n.ProjectDashboard.titleCO2Trucks}</DashboardTitle>
            </div>
            <ResponsiveContainer height={220}>
                <BarChart data={series}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="licensePlate" />
                    <YAxis />
                    <Tooltip
                        content={
                            <SimpleDashboardToolTip
                                labelFormatter={v => `${i18n.ProjectDashboard.regNumber}: ${v}`}
                                labelKey="licensePlate"
                                valueKey="CO2"
                                valueLabel="CO2"
                                valueFormatter={formatKg}
                            />
                        }
                        shared={false}
                    />
                    <Bar type="monotone" dataKey="CO2" fill={COLOR.primaryStartColor} />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
});

const useStyles = makeStyles(theme => ({
    chart: {
        height: 'calc(50vh - 160px)',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        },
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        flexWrap: 'wrap',
        gap: 8,
    },
}));
